import React from "react";
import { Link, navigate } from "gatsby";
import { grey } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import ArticleMeta from "./ArticleMeta";

const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    maxWidth: 600,
    "&.card-clickable": {
      cursor: "pointer",
    },
    "&:hover, &:active, &:focus": {
      backgroundColor: grey["100"],
      boxShadow:
        "0px 2px 4px -1px rgba(0,0,0,0.15), 0px 1px 4px 0px rgba(0,0,0,0.02), 0px 1px 10px 0px rgba(0,0,0,0.2)",
    },
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  header: {
    "& a": {
      color: "inherit",
      textDecoration: "none",
      "&:hover, &:active, &:focus": {
        textDecoration: "underline",
      },
    },
    '& [class*="subheader"]': {
      fontSize: theme.typography.fontSize,
      "& a": {
        color: "inherit",
        textDecoration: "underline",
        "&:hover, &:active, &:focus": {
          textDecoration: "none",
        },
      },
    },
    "& > *": {
      display: "block",
      "& > span": {
        display: "block",
        marginTop: theme.spacing(1),
        "&:first-child": {
          marginTop: 0,
        },
      },
    },
    "& h2": {
      fontSize: "1.4rem",
      lineHeight: 1.4,
    },
  },
  content: {
    paddingTop: 0,
    "& > *": {
      marginBottom: theme.spacing(2),
      "&:last-child": {
        marginBottom: 0,
      },
    },
  },
}));

export default function NewsCard({
  title,
  tags,
  type,
  date,
  to,
  thumbnail,
  description,
  member,
}) {
  const classes = useStyles();

  if (!title) return <></>;

  function handleClick(e) {
    e.preventDefault();
    if (to) navigate(to);
  }

  let title_dom = to ? (
    <Link to={to} onClick={(e) => e.stopPropagation()}>
      {title}
    </Link>
  ) : (
    title
  );

  let terms = tags && tags.length > 0 ? tags : type && type.length > 0 ? type : null;
  let subheader =
    (terms && terms.length > 0) || date ? <ArticleMeta tags={terms} date={date} /> : null;

  return (
    <Card
      className={`${classes.root} ${to ? "card-clickable" : ""}`}
      component="article"
      onClick={handleClick}
    >
      {thumbnail && (
        <CardMedia
          component="a"
          href={to}
          onClick={(e) => e.preventDefault()}
          className={classes.media}
          image={thumbnail.src}
        />
      )}
      <CardHeader
        title={<Typography component="h2">{title_dom}</Typography>}
        className={classes.header}
        component="header"
        subheader={subheader}
      />
      <CardContent className={classes.content}>
        {description && (
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
}
