import React from "react";
import { Link } from "gatsby";

function ArticleMeta({ tags, date }) {
  let tags_dom = [];
  if (tags && tags.length > 0) {
    tags_dom = tags.map((tag_data, i) => (
      <span key={tag_data.id}>
        {!i && date ? " - " : !i ? "" : ",  "}
        {tag_data.to ? (
          <Link to={tag_data.to} onClick={(e) => e.stopPropagation()}>
            {tag_data.label}
          </Link>
        ) : (
          <span style={{ textDecoration: "underline" }}>{tag_data.label}</span>
        )}
      </span>
    ));
  }

  let subheader = null;
  if (date || tags_dom.length > 0) {
    subheader = (
      <>
        {date ? date : ""}
        {tags_dom.length > 0 && tags_dom}
      </>
    );
  }
  return <>{subheader}</>;
}

export default ArticleMeta;
