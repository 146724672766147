export default function processFilesToLinks(field, drupalUrl) {
  return field
    .filter((media) => {
      return (
        media?.relationships?.field_media_document?.uri?.url ||
        media?.relationships?.field_media_file?.uri?.url ||
        (media.field_from_url && media.field_from_url !== null)
      );
    })
    .map((media) => {
      let is_rel =
        media.relationships &&
        media.relationships.field_media_document &&
        media.relationships.field_media_document.uri &&
        media.relationships.field_media_document.uri.url
          ? true
          : false;

      let is_private = media?.relationships?.field_media_file?.uri?.url ? true : false;

      let url = is_rel
        ? `${drupalUrl}${media.relationships.field_media_document.uri.url}`
        : is_private
        ? `${drupalUrl}${media.relationships.field_media_file.uri.url}`
        : media.field_from_url.uri;
      return {
        external: true,
        id: media.drupal_id,
        to: url,
        label: media.name,
      };
    });
}
