import { slugify } from "../util";

export default function processMember(node, drupalUrl) {
  let member = {
    id: node.drupal_id,
    name: node.title,
    jobTitle: node.field_job_title,
    phone: node.field_phone,
    email: node.field_email,
    categories: [],
    slug: `/${slugify(node.title)}`,
    profiles: [],
  };
  if (node.body) {
    member.intro = node.body.summary;
  }
  if (node.field_linkedin_profile) {
    member.profiles.push({
      site: "linkedin",
      url: node.field_linkedin_profile.uri,
    });
  }
  if (node.field_twitter_profile) {
    member.profiles.push({
      site: "twitter",
      url: node.field_twitter_profile.uri,
    });
  }
  if (
    node.relationships &&
    node.relationships.field_photo &&
    node.relationships.field_photo.relationships &&
    node.relationships.field_photo.relationships.field_media_image &&
    node.relationships.field_photo.relationships.field_media_image.uri &&
    node.relationships.field_photo.relationships.field_media_image.uri.url
  ) {
    member.avatar = `${drupalUrl}${node.relationships.field_photo.relationships.field_media_image.uri.url}`;
  }
  if (
    node.relationships &&
    node.relationships.field_team_category &&
    node.relationships.field_team_category.length > 0
  ) {
    member.categories = node.relationships.field_team_category.map(
      ({ drupal_id, name }) => ({ id: drupal_id, name })
    );
  }
  return member;
}
