import findPathFromEntityId from "./findPathFromEntityId";

export default function processDrupalUri(uri, allNodeRefs) {
  const is_internal = uri.includes("internal:");
  const is_entity_ref = uri.includes("entity:");
  return is_internal
    ? uri.replace("internal:", "")
    : is_entity_ref
    ? findPathFromEntityId(uri.split("/")[1], allNodeRefs)
    : uri;
}
