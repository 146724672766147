import { slugify, richtextParse } from "../util";
import processMember from "./processMember";
import processFilesToLinks from "./processFilesToLinks";
import processDrupalUri from "./processDrupalUri";

const multi_relationships = {
  field_tags: {
    id: "drupal_id",
    label: "name",
    path: "/tags/",
  },
};

export default function processNodes(
  data,
  drupalUrl,
  pageContext,
  recentQueries,
  allNodeRefs
) {
  let articles =
    data && data.edges
      ? data.edges.map(({ node }) => {
          let article = {
            id: node.drupal_id,
            title: node.title,
            searchString: `${node.title} `,
            anchors: [],
          };

          if (node.body) {
            article.description = node.body.summary;
            article.billboard = node.body.summary;
            article.searchString += `${node.body.summary} `;
          }

          if (node.field_date) {
            article.date = node.field_date;
          }

          if (node.sticky) {
            article.sticky = true;
          }

          if (
            node.field_display_contact_form &&
            node.field_display_contact_form === true
          ) {
            article.display_contact_form = true;
          }

          article.to = node.fields && node.fields.slug ? node.fields.slug : null;

          article.disable_main_header = node.field_disable_main_header === true;

          let body_content = "";
          if (drupalUrl) {
            if (node.body) body_content += `${node.body.processed} `;
          }

          if (body_content.length > 0) {
            let parsed_content = richtextParse(body_content, drupalUrl);
            article.content = parsed_content.html;
            article.anchors = parsed_content.anchors;
          }

          if (node.relationships) {
            if (node.relationships.field_banner_image) {
              article.banner = `${drupalUrl}${node.relationships.field_banner_image.relationships.field_media_image.uri.url}`;
            }
            if (node.relationships.field_header_background_image) {
              article.header_background = `${drupalUrl}${node.relationships.field_header_background_image.relationships.field_media_image.uri.url}`;
            }

            if (
              node.relationships.field_accordion_nodes &&
              node.relationships.field_accordion_nodes.length > 0
            ) {
              article.accordion = {
                title: node.field_accordion_title ? node.field_accordion_title : "",
                slug: node.field_accordion_title
                  ? slugify(node.field_accordion_title)
                  : null,
                tabs: node.relationships.field_accordion_nodes.map((node) => {
                  let tab_content = "";
                  if (drupalUrl && node.field_accordion_tab_content) {
                    let parsed_content = richtextParse(
                      node.field_accordion_tab_content.processed,
                      drupalUrl
                    );
                    tab_content = parsed_content.html;
                  }

                  let tab = {
                    id: node.drupal_id,
                    title: node.title,
                    content: tab_content,
                    links:
                      node.relationships.field_accordion_tab_files &&
                      node.relationships.field_accordion_tab_files.length > 0
                        ? processFilesToLinks(
                            node.relationships.field_accordion_tab_files,
                            drupalUrl
                          )
                        : [],
                  };
                  return tab;
                }),
              };
              if (node.field_accordion_title) {
                article.anchors.push({
                  id: slugify(node.field_accordion_title),
                  title: node.field_accordion_title,
                });
              }
            }

            if (
              node.relationships.field_quicklinks_files &&
              node.relationships.field_quicklinks_files.length > 0
            ) {
              let quicklinks_desc = node.field_quicklinks_description
                ? `<p>${node.field_quicklinks_description}</p>`
                : "";
              let formatted_desc = "";
              if (drupalUrl && node.field_description_formatted) {
                formatted_desc += `${node.field_description_formatted.processed}`;
                if (formatted_desc.length > 0) {
                  let parsed_content = richtextParse(formatted_desc, drupalUrl);
                  quicklinks_desc += " " + parsed_content.html;
                }
              }
              article.quicklinks = {
                title:
                  node.field_quicklinks_title && node.field_quicklinks_title.length > 0
                    ? node.field_quicklinks_title
                    : "Resource Files",
                description: quicklinks_desc,
                links: processFilesToLinks(
                  node.relationships.field_quicklinks_files,
                  drupalUrl
                ),
              };
            }

            Object.keys(multi_relationships).forEach((field_key) => {
              let field_formatted_key = field_key.replace("field_", "");
              let field_config = multi_relationships[field_key];
              article[field_formatted_key] =
                node.relationships[field_key] && node.relationships[field_key].length > 0
                  ? node.relationships[field_key].map((node) => {
                      article.searchString += `${node[field_config.label]} `;
                      let item = {
                        id: node.drupal_id,
                        label: node[field_config.label],
                      };
                      if (field_config.path) {
                        item.to = `${field_config.path}${slugify(
                          node[field_config.label]
                        )}`;
                      }
                      return item;
                    })
                  : [];
            });

            if (
              node.relationships.field_page_content_blocks &&
              node.relationships.field_page_content_blocks.length > 0
            ) {
              article.content_blocks = node.relationships.field_page_content_blocks
                .filter((block_data) => typeof block_data.drupal_id !== "undefined")
                .map((block_data) => {
                  let block = {
                    id: block_data.drupal_id,
                    title: block_data.title,
                    type: block_data.internal.type.slice(6),
                  };
                  if (block.type === "call_to_action") {
                    block.background = block_data.field_call_to_action_background;
                    block.heading_type = block_data.field_call_to_action_heading_typ;
                    block.subhead = block_data.field_call_to_action_subhead;
                    block.content = block_data.field_call_to_action_content;
                    block.button = {
                      title: block_data.field_call_to_action_link.title,
                      uri: processDrupalUri(
                        block_data.field_call_to_action_link.uri,
                        allNodeRefs
                      ),
                    };
                    if (block_data.relationships) {
                      if (
                        block_data.relationships.field_call_to_action_logo &&
                        block_data.relationships.field_call_to_action_logo
                          .relationships &&
                        block_data.relationships.field_call_to_action_logo.relationships
                          .field_media_image
                      ) {
                        block.logo = `${drupalUrl}${block_data.relationships.field_call_to_action_logo.relationships.field_media_image.uri.url}`;
                      }
                      if (
                        block_data.relationships.field_header_background_image &&
                        block_data.relationships.field_header_background_image
                          .relationships &&
                        block_data.relationships.field_header_background_image
                          .relationships.field_media_image
                      ) {
                        block.header_background = `${drupalUrl}${block_data.relationships.field_header_background_image.relationships.field_media_image.uri.url}`;
                      }
                    }
                  } else if (block.type === "section_header") {
                    if (
                      block_data.relationships &&
                      block_data.relationships.field_section_header_banner_img &&
                      block_data.relationships.field_section_header_banner_img
                        .relationships &&
                      block_data.relationships.field_section_header_banner_img
                        .relationships.field_media_image
                    ) {
                      block.banner = `${drupalUrl}${block_data.relationships.field_section_header_banner_img.relationships.field_media_image.uri.url}`;
                    }
                  } else if (block.type === "content_row") {
                    let block_content = "";
                    if (drupalUrl && block_data.field_content_row_content) {
                      block_content += `${block_data.field_content_row_content.processed} `;
                    }
                    if (block_content.length > 0) {
                      let parsed_content = richtextParse(block_content, drupalUrl);
                      block.content = parsed_content.html;
                    }
                    if (block_data.field_content_row_more_link) {
                      block.button = {
                        title: block_data.field_content_row_more_link.title,
                        uri: processDrupalUri(
                          block_data.field_content_row_more_link.uri,
                          allNodeRefs
                        ),
                      };
                    }
                    if (
                      block_data.field_content_row_display_recent &&
                      recentQueries &&
                      typeof recentQueries[
                        block_data.field_content_row_display_recent
                      ] !== "undefined" &&
                      recentQueries[block_data.field_content_row_display_recent].edges &&
                      recentQueries[block_data.field_content_row_display_recent].edges
                        .length > 0
                    ) {
                      block.recent = {
                        type: "news",
                        data: recentQueries[block_data.field_content_row_display_recent],
                      };
                    }
                    if (block_data.relationships) {
                      if (
                        block_data.relationships.field_content_row_files &&
                        block_data.relationships.field_content_row_files.length > 0
                      ) {
                        let block_quicklinks_desc = block_data.field_content_row_files_desc
                          ? `<p>${block_data.field_content_row_files_desc}</p>`
                          : "";
                        let formatted_desc = "";
                        if (drupalUrl && block_data.field_description_formatted) {
                          formatted_desc += `${block_data.field_description_formatted.processed}`;
                          if (formatted_desc.length > 0) {
                            let parsed_content = richtextParse(formatted_desc, drupalUrl);
                            block_quicklinks_desc += " " + parsed_content.html;
                          }
                        }

                        block.quicklinks = {
                          title: block_data.field_content_row_files_title,
                          description: block_quicklinks_desc,
                          links: processFilesToLinks(
                            block_data.relationships.field_content_row_files,
                            drupalUrl
                          ),
                        };
                      }
                      if (block_data.relationships.field_content_row_video) {
                        var video_id = block_data.relationships.field_content_row_video.field_media_oembed_video.split(
                          "v="
                        )[1];
                        var ampr = video_id.indexOf("&");
                        if (ampr !== -1) {
                          video_id = video_id.substring(0, ampr);
                        }
                        block.youtube = {
                          id: video_id,
                          title: block_data.relationships.field_content_row_video.name,
                        };
                      }
                      if (
                        block_data.relationships.field_personnel_list &&
                        block_data.relationships.field_personnel_list.length > 0
                      ) {
                        block.personnel = block_data.relationships.field_personnel_list.map(
                          (node) => processMember(node, drupalUrl)
                        );
                      }
                    }
                  } else if (block.type === "highlight") {
                    if (block_data.field_highlight_link) {
                      block.button = {
                        title: block_data.field_highlight_link.title,
                        uri: processDrupalUri(
                          block_data.field_highlight_link.uri,
                          allNodeRefs
                        ),
                      };
                    }

                    let parsed_highlight_content = block_data.field_highlight_content
                      ? richtextParse(
                          block_data.field_highlight_content.processed,
                          drupalUrl
                        )
                      : null;
                    block.content = parsed_highlight_content.html;
                  }
                  return block;
                });
            }
          }

          if (pageContext && (pageContext.prev || pageContext.next)) {
            article.nextPrev = {};
            for (const key of ["prev", "next"]) {
              if (pageContext[key]) {
                article.nextPrev[key] = {
                  to: pageContext[key].fields.slug,
                  label: pageContext[key].title,
                  id: pageContext[key].drupal_id,
                };
              }
            }
          }

          return article;
        })
      : [];

  if (articles.length < 2) return articles;

  let sticky_articles = articles.filter((f) => f.sticky === true);
  let non_sticky_articles = articles.filter((f) => f.sticky !== true);
  let all_articles = [...sticky_articles, ...non_sticky_articles];
  let limit = pageContext && pageContext.limit ? pageContext.limit : 0;
  let current_page = pageContext && pageContext.currentPage ? pageContext.currentPage : 1;
  if (limit) {
    return all_articles.splice(limit * (current_page - 1), limit);
  } else {
    return all_articles;
  }
}
